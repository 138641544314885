@import 'themes/page-loader';
@import 'variables.scss';

@mixin center-by-translating() {
  transform: translate(-50%, -50%);
}

wd-root {
  display: block;
  opacity: 0;
}

wd-root.loaded {
  opacity: 1;
  transition: opacity 1s ease;
}

#wd-page-loader {
  position: fixed;

  width: 100%;

  z-index: 999;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 200%;
  font-weight: lighter;

	.inner {
		position: absolute;
		left: 50%;
    top: 50%;
    width: 48px;
    height: 48px;

    @include center-by-translating();
    overflow: visible;

    > .rotating-container {
      position: absolute;
      top: 0;
      left: 0;

      width: 48px;
      height: 48px;

      animation: rotating 1s ease infinite;
      overflow: visible;

      > .diamond {
        width: 48px;
        height: 48px;
        transform: translate(0, -55px);
      }

      > .star-1, .star-2, .star-3 {
        position: absolute;
        top: 10px;
        left: 10px;

        width: 24px;
        height: 24px;
        > svg {
          width: 24px;
          height: 24px;
          transform-origin: 24px 24px;
        }
      }

      > .star-1 {
        transform: translate(-30px, -75px);
        > svg {
          animation: star-1 1s linear infinite;
          animation-delay: -0.1s;
        }
      }

      > .star-2 {
        transform: translate(25px, -90px);
        > svg {
          animation: star-2 1s linear infinite;
        }
      }

      > .star-3 {
        transform: translate(35px, -75px);
        > svg {
          animation: star-3 1s linear infinite;
          animation-delay: 0.1s;
        }
      }
    }
	}

	.ring {
		display: block;
		width: 60px;
		height: 60px;
		position: relative;

		&:before {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			border-radius: 50%;
		}
	}
}

@keyframes rotating {
	from {
		transform: rotate3d(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@mixin keyframes($name, $scale, $rotation) {
  @keyframes #{$name} {
    0% {
      opacity: 1;
      transform: scale($scale) rotate($rotation);
    }

    20% {
      opacity: 0;
      transform: scale($scale) rotate($rotation);
    }

    80% {
      opacity: 0;
      transform: scale($scale) rotate(0);
    }

    100% {
      opacity: 1;
      transform: scale($scale) rotate($rotation);
    }
  }
}

@include page-loader-theme($app-theme);

@include keyframes("star-1", 0.7, 0);
@include keyframes("star-2", 1, 0);
@include keyframes("star-3", 0.9, 0);
