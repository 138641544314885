@import '~@angular/material/theming';

@mixin page-loader-theme($theme) {
  $primary: map-get($theme, primary);
  $theme-primary-color: mat-color($primary, 500);

  #wd-page-loader {
    background: #fff;

    .inner {

      > .rotating-container {

        > .diamond {

          fill: $theme-primary-color;
        }

        > .star-1, .star-2, .star-3 {
          fill: rgba($theme-primary-color, 0.5);
        }
      }
    }

    .ring {
      &:before {
        border: 5px solid #f1f1f1;
      }
    }
  }
}
